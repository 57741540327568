:root {
  --background: #f0f5f4;
  --background-dark: #102733;
  --main-color: #9cd6d7;
  --second-color: #d8d981;
}

body, html {
  font-family: 'League Spartan', sans-serif;
  font-weight: lighter;
  font-size: 18px;
  line-height: 1.15;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html::before {
  background: var(--second-color);
  transform: translateX(170%);

}

html::after {
  background: var(--main-color);
  transform: translateX(-170%);
}

html::before, html::after {
  content: "";
  position: fixed;
  height: 9999px;
  width: 100%;
  top: 0;
  left: 0;
  transition: all 1.2s ease-in-out;
  z-index: 0;
}


html.light::after {
  background: linear-gradient(286deg, #e8f5f5, #ffffff00);
  transform: translateX(0);
}

html.light::before {
  transform: translateX(-190%);
}

html.dark::before {
  background: linear-gradient(125deg, #051b29, #031316);
  transform: translateX(0);
}

html.dark::after {
  transform: translateX(-190%);
}
